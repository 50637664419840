import { createContext, useState, useEffect, FC, ReactNode } from 'react';
import io, { Socket } from 'socket.io-client';

// Create sockets once (no need to call connect manually)
const notificationsSocket = io(`${process.env.REACT_APP_ROOT_URL}`, {
  path: '/notifications-socket/',
  reconnection: true, // Ensures reconnection attempts
  reconnectionAttempts: 10,
  reconnectionDelay: 1000
});

const messagingSocket = io(`${process.env.REACT_APP_ROOT_URL}`, {
  path: '/messaging/',
  reconnection: true, // Ensures reconnection attempts
  reconnectionAttempts: 10,
  reconnectionDelay: 1000
});

interface SocketIOContextValue {
  messagingSocket: Socket;
  isMsgsConnected: boolean;
  notificationsSocket: Socket;
  isNotificationsConnected: boolean;
}

interface SocketIOProviderProps {
  children: ReactNode;
}

const SocketIOContext = createContext<SocketIOContextValue>({
  messagingSocket: {} as Socket,
  isMsgsConnected: false,
  notificationsSocket: {} as Socket,
  isNotificationsConnected: false
});

const SocketIOProvider: FC<SocketIOProviderProps> = ({ children }) => {
  const [isMsgsConnected, setIsMsgsConnected] = useState(messagingSocket.connected);
  const [isNotificationsConnected, setIsNotificationsConnected] = useState(notificationsSocket.connected);

  useEffect(() => {
    // Message socket connection handlers
    const handleMsgConnect = () => setIsMsgsConnected(true);
    const handleMsgDisconnect = () => setIsMsgsConnected(false);

    // Notifications socket connection handlers
    const handleNotificationsConnect = () => setIsNotificationsConnected(true);
    const handleNotificationsDisconnect = () => setIsNotificationsConnected(false);

    // Attach listeners
    messagingSocket.on('connect', handleMsgConnect);
    messagingSocket.on('disconnect', handleMsgDisconnect);

    notificationsSocket.on('connect', handleNotificationsConnect);
    notificationsSocket.on('disconnect', handleNotificationsDisconnect);

    // Clean up the listeners when component unmounts
    return () => {
      messagingSocket.off('connect', handleMsgConnect);
      messagingSocket.off('disconnect', handleMsgDisconnect);
      notificationsSocket.off('connect', handleNotificationsConnect);
      notificationsSocket.off('disconnect', handleNotificationsDisconnect);
    };
  }, []);

  return (
    <SocketIOContext.Provider
      value={{
        messagingSocket,
        isMsgsConnected,
        notificationsSocket,
        isNotificationsConnected
      }}
    >
      {children}
    </SocketIOContext.Provider>
  );
};

export { SocketIOContext, SocketIOProvider };
